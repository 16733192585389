<template>
    <div>
        <modal-content ref="add-block" :max-width="480" name="add-block" title="Add New Block" @close="closeBlockModal" :showFooter="false">
            <template #content>
                <FormulateForm @submit="handleClick" #default="{ hasErrors }" class="min-h-full flex-1">
                    <div class="flex flex-col justify-between min-h-full">
                        <FormulateInput class="w-full text-base-content" label="Name" placeholder="Block Name" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="blockData.name" />

                        <FormulateInput class="w-full text-base-content" label="Label" placeholder="Block Label" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="blockData.label" />

                        <FormulateInput class="w-full text-base-content" label="Block Title" placeholder="Block Title" type="text" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="blockData.block_title" />
                        
                        <FormulateInput class="w-full text-base-content" label="Description" placeholder="Block Description" type="textarea" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" v-model="blockData.description" />

                        <FormulateInput class="text-base-content" label="Multi" placeholder="multi" type="checkbox" :element-class="(context, classes) => ['pr-2'].concat(classes)" v-model="blockData.multi" />

                        <div class="flex gap-4">
                            <FormulateInput type="number" class="w-full text-base-content" label="Min" :min="blockData.multi ? 1: ''" placeholder="Min Count" :validation="blockData.multi ? 'number|min:1': ''" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" :disabled="!blockData.multi" @keypress="isNumber($event)" v-model="blockData.min_count" />
                            <FormulateInput type="number" class="w-full text-base-content" label="Max" :min="blockData.multi ? blockData.min_count: ''" placeholder="Max Count" :validation="blockData.multi ? `number|min:${blockData.min_count}`: ''" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" :disabled="!blockData.multi" @keypress="isNumber($event)" v-model="blockData.max_count" />
                            <!-- <input type="number" min=0 class="w-full border h-10 px-2" label="Min" :disabled="!blockData.multi" @keypress="isNumber($event)" v-model="blockData.min_count" />
                            <input type="number" class="w-full border h-10 px-2" label="Max" :disabled="!blockData.multi" @keypress="isNumber($event)" v-model="blockData.max_count" /> -->
                        </div>

                        <div class="card-actions justify-end">
                            <Button :loader="isLoading" text="Save" type="primary" :disabled="hasErrors || isLoading || isButtonDisabled" />
                        </div>
                    </div>
                </FormulateForm>
            </template>
        </modal-content>
    </div>
</template>
<script>
import Button from "@/components/button";
import modalContent from "@/components/modal-content";
import { isNumber } from "@/plugins/functions";

export default {
    name: "add-block",
    components: {
        Button,
        modalContent,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {},
    data() {
        return {
            blockData: {
                name: "",
                label: "",
                description: "",
                multi: false,
                min_count: 1,
                max_count: 1,
            },
        };
    },
    computed: {
        isButtonDisabled() {
            if (this.blockData.name === "" || this.blockData.label === "" || this.blockData.description === "") return true;
            else return false;
        },
    },
    methods: {
        isNumber,
        closeBlockModal() {
            this.$refs["add-block"].hideModal();
        },
        showBlockModal() {
            this.handleClearBlockData();
            this.$refs["add-block"].showModal();
        },
        handleClearBlockData() {
            this.blockData = {
                name: "",
                label: "",
                description: "",
                multi: false,
                min_count: 1,
                max_count: 1,
            };
        },
        handleClick() {
                this.blockData.name = this.blockData.name.trim();
                this.blockData.label = this.blockData.label.trim();
                this.blockData.description = this.blockData.description.trim();

            if(this.blockData.name.length===0 || this.blockData.label.length===0 || this.blockData.description.length===0){
                this.$toast.error("Any field should not be filled with spaces only");
            }
            else if (this.blockData.multi && (this.blockData.min_count < 1 || this.blockData.max_count < 1)) {
                this.$toast.error("min, max should be greater than 0");
            } else {
                
                this.$emit("createNewBlock", this.blockData);
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
